import React from 'react'
import CardItem from '../components/CardItem'
import '../components/Cards.css'

function EntityManagement() {
    return (
        <div className='cards'>            
            <div className="cards__container">
                <div className="cards__wrapper">
                    <ul className="cards__items">
                        <li className="cards__item">
                            <div className="cards__item__link" >
                                <figure className="cards__item__pic-wrap2" data-category="">
                                    <img src="images/image0.png" alt="Dashboard Image" className="cards__item__img2" />
                                </figure>                                
                            </div>
                        </li>
                        <CardItem
                            src="images/image1.jpg"
                            text="Dashboard category - leads to more dashboards"
                            label="Placeholder"
                            path="/dashboards/customer/"
                        />
                        <CardItem
                            src="images/image2.jpg"
                            text="Shows the shareholder and entities for the top 100 entities by net worth"
                            label="Shareholders"
                            path="/dashboards/shareholders"
                        />
                        <CardItem
                            src="images/image3.jpg"
                            text="Shows the current company registered address and billing address status"
                            label="Addresses"
                            path="/dashboards/addresses"
                        />


                    </ul>
                    <ul className="cards__items">
                        <CardItem
                            src="images/placeholder.jpeg"
                            text="New dashboard description will come here"
                            label="Coming Soon"
                            path="/"
                        />
                        <CardItem
                            src="images/placeholder.jpeg"
                            text="New dashboard description will come here"
                            label="Coming Soon"
                            path="/"
                        />
                        <CardItem
                            src="images/placeholder.jpeg"
                            text="New dashboard description will come here"
                            label="Coming Soon"
                            path="/"
                        />
                        <CardItem
                            src="images/placeholder.jpeg"
                            text="New dashboard description will come here"
                            label="Coming Soon"
                            path="/"
                        />



                    </ul>
                </div>
            </div>
        </div>
    )
}

export default EntityManagement

/* 
Need to pass properties so that the cards show up


import React from 'react'
import CardItem from './CardItem'
import './Cards.css';

function Cards() {
  return (
    <div className='cards'>
        <h1>Check out these EPIC Destinations!</h1>
        <div className="cards__container">
            <div className="cards__wrapper">
                <ul className="cards__items">
                    <CardItem />
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Cards
 */