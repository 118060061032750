import React from 'react'
import SupersetDashboard from '../components/Dashboard'

function Shareholders(inputToken) {
    /*const [token, setToken] = React.useState("")

     const handleClick = async () => {
      return(
        <SupersetDashboard 
          title="Shareholders"
          id="10a1115a-c63a-42a3-9853-942da94182ec"
          inputToken={token}
      />
      ) 

    }*/
  return (
    <>   
        {/* <input placeholder='Enter an ID' value={token} onChange={e => setToken(e.target.value)}  />
        <button onClick={handleClick()}>Refresh Dashboard</button> */}
        <SupersetDashboard 
          title="Shareholders"
          id="10a1115a-c63a-42a3-9853-942da94182ec"
          inputToken="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJuYW1lIjoiQXBpVXNlciIsImZpcnN0X25hbWUiOiJBcGlVc2VyIiwibGFzdF9uYW1lIjoiQXBpVXNlciJ9LCJyZXNvdXJjZXMiOlt7InR5cGUiOiJkYXNoYm9hcmQiLCJpZCI6ImM3YjYyMmRhLTY3N2ItNDMyOS05MmZiLTdiZWQ1NzIzZWQ5NCJ9LHsidHlwZSI6ImRhc2hib2FyZCIsImlkIjoiMTBhMTExNWEtYzYzYS00MmEzLTk4NTMtOTQyZGE5NDE4MmVjIn1dLCJybHNfcnVsZXMiOltdLCJpYXQiOjE3MTY0OTI5NzIuMTM5MTQxLCJleHAiOjE3MTY0OTMyNzIuMTM5MTQxLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjgwODgiLCJ0eXBlIjoiZ3Vlc3QifQ.MrTEoeEkZJ3L3OLjpYcVzj21Yhhb0ing5F8-nPHYKzo"
      />
    </>
  )
}

export default Shareholders