import { React, useEffect } from 'react';
import { embedDashboard } from "@superset-ui/embedded-sdk";
import Login from './Login';


function SupersetDashboard(props) {

    const getToken = async () => {
        const guestToken = await Login(props.inputToken);
        return guestToken
    }

    useEffect(() => {
        const embed = async () => {
            await embedDashboard({                
                id: props.id, // given by the Superset embedding UI
                supersetDomain: "http://102.133.149.243/",
                mountPoint: document.getElementById("dashboard"), // html element in which iframe render
                fetchGuestToken: () => getToken(),                
                dashboardUiConfig: {
                    hideTitle: true,
                    hideChartControls: true,
                    hideTab: true,
                },
            })
        }        
        if (document.getElementById("dashboard")) {
            embed()
        }

    }, [])

    return (
        <div className={"DashboardContainer_"+props.title}>            
            <h1>{props.title}</h1>
            <div id="dashboard" />
        </div>
    )
}

export default SupersetDashboard;