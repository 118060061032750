import React from 'react';
import { BrowserRouter,  Routes, Route } from 'react-router-dom';
import "./App.css";
import Shareholders from "./dashboards/Shareholders";
import Addresses from "./dashboards/Addresses"
import EntityManagement from './pages/Home';


function App() {
  return (
    <>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<EntityManagement />} />
        <Route path='/dashboards/shareholders' element={<Shareholders />} />
        <Route path='/dashboards/addresses' element={<Addresses />} />
      </Routes>
      
      </BrowserRouter>
    </>
  )
}

export default App