
async function Login(inputToken) {

    //const accessToken = await AccessToken();
    //console.log("Login 111 - accessToken");
    //console.log(accessToken);

    try{
    const responseAccessToken = await fetch("http://102.133.149.243/api/v1/security/login", {
        method: "POST",
        body: JSON.stringify({
            provider: "db",
            refresh: true,
            username: "admin",
            password: "VistraSucks01"
        }),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    })
    const responseAccessToken_Json = await responseAccessToken.json();
    const responseAccessToken_token = responseAccessToken_Json.access_token
    console.log('access_token');
    console.log(responseAccessToken_token)


    //const guestToken = await GuestToken(responseAccessToken_token);
    //console.log("Login 222 - guestToken");
    //console.log(guestToken);

    const data = {
        "user": {
            "username": "ApiUser",
            "first_name": "ApiUser",
            "last_name": "ApiUser"
        },
        "resources": [
            {
                "type": "dashboard",
                "id": "10a1115a-c63a-42a3-9853-942da94182ec"
            },
            {
                "type": "dashboard",
                "id": "c7b622da-677b-4329-92fb-7bed5723ed94"
            }
        ],
        "rls": []
    };

    const requestBody = JSON.stringify(data);
    console.log("222-1 - requestBody");
    console.log(requestBody);

    const responseGuestToken = await fetch("http://102.133.149.243/api/v1/security/guest_token/", {
        method: "POST",
        body: requestBody,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": "Bearer " + responseAccessToken_token
        }
    });
    const responseGuestToken_Json = await responseGuestToken.json();
    const responseGuestToken_token = responseGuestToken_Json.token
    console.log('guest_token');
    console.log(responseGuestToken_token)
    return (responseGuestToken_token)
}
catch {console.log("inputToken="+inputToken);
    return (inputToken)
}
    
    
}

export default Login


